import React from "react";
import data from "../data.json";
import "./item.scss";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Image, InputNumber } from "antd";
import { useState } from "react";

const categories = [
  "Perdea Lumini de Exterior/Interior",
  "Coronite de brad artificial",
  "Decorațiuni deosebite",
  "Ghirlanda din pin artificial",
  "Decoratiuni si globuri",
];

const Item = () => {
  let location = useLocation();
  var id = location.pathname.split("/")[3].split("-")[0];
  const item = data.filter((item) => item.id == parseInt(id))[0];

  const [quantity, setQuantity] = React.useState(1);
  const category = categories[item.category - 1];

  const handleAddToCart = () => {
    //add to local storage
    //refresh page
    if (localStorage.getItem("cart") === null) {
      localStorage.setItem(
        "cart",
        JSON.stringify([
          {
            id: parseInt(id),
            quantity: parseInt(quantity),
          },
        ])
      );
    } else {
      let cart = JSON.parse(localStorage.getItem("cart"));
      cart.push({
        id: parseInt(id),
        quantity: parseInt(quantity),
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    window.location.href = "/cart";
  };
  return (
    <div className="itemLayout">
      <Helmet
        title={`${item.name} - Crăciun de lux`}
        description={`Aruncă o privire asupra produsului ${item.name}. ${category} - Crăciun de lux.`}
        keywords="Categorii - Crăciun de lux"
      />
      <div className="item-wrapper">
        {/* title, old price striked, new price next to it, button to order below, categories below */}
        <div className="item">
          <div className="item-image">
            <Image src={item.img_src} />
          </div>
          <div className="right">
            <div className="item-title">{item.name}</div>
            <div className="item-price">
              <div className="old-price">{item.old_price} lei</div>
              <div className="new-price">{item.new_price} lei</div>
            </div>
            <div className="form_item">
              <div className="label">Cantitate</div>
              <div className="item-button">
                <InputNumber
                  size="large"
                  defaultValue={1}
                  min={1}
                  max={10}
                  style={{ width: 50 }}
                  onChange={(value) => setQuantity(value)}
                />
                <button onClick={handleAddToCart}>Adaugă în coș</button>
              </div>
            </div>
            <p>Categorie: {category}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
