import React from "react";
import { useState, useEffect } from "react";
import "./mainHeader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUser } from "@fortawesome/free-regular-svg-icons";
import { faSearch, faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "antd";
const MainHeader = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 300) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`mainHeader ${scrolled ? "scrolled" : ""}`}>
      <div className="container">
        <div className="main-header">
          <div className="col-1">
            <div className="logo">
              <a href="/">
                <img src="/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
          <div className="col-2">
            <ul className="menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/magazin/">Categorii</a>
              </li>
              <li>
                <a href="/contact-us/">Contact</a>
              </li>
            </ul>
          </div>
          <div className="col-3">
            <ul className="menu">
              {/* fa search, fa heart, fa person,fa shopping bag */}
              {/* <li>
                <a href="/">
                  <FontAwesomeIcon icon={faSearch} />
                </a>
              </li>
              <li>
                <a href="/">
                  <FontAwesomeIcon icon={faHeart} />
                </a>
              </li>
              <li>
                <a href="/">
                  <FontAwesomeIcon icon={faUser} />
                </a>
              </li> */}
              <li>
                <Badge
                  count={
                    JSON.parse(localStorage.getItem("cart"))
                      ? JSON.parse(localStorage.getItem("cart")).length
                      : 0
                  }
                >
                  <a href="/cart">
                    <FontAwesomeIcon icon={faShoppingBag} />
                  </a>
                </Badge>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
