import React from "react";
import "./despreNoi.scss";

const DespreNoi = () => {
  return (
    <div className="desprenoi">
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">Despre noi</div>
          <div className="breadcrumbs">
            <a href="/">Home</a>
          </div>
        </div>
      </div>
      <div className="container">
        <p>
          Într-un mic colț de internet, sub lumina strălucitoare a stelelor,
          există un loc magic numit „Craciun de Lux”. Este o lume plină de
          farmec și bucurie, unde spiritele de Crăciun dansează în jurul
          bradului împodobit cu gingășie și măiestrie.
        </p>
        <p>
          În fiecare an, când zăpada îmbracă orașul în haine albe și liniștea
          aduce cu sine aromele delicioase ale cozonacilor și a bradului de
          Crăciun, magazinul online „Craciun de Lux” prinde viață. Fiecare
          colțișor al acestui univers online este îmbăiat în strălucirea festivă
          a luminilor și îmbăiat în miresmele dulci ale iernii.
        </p>
        <p>
          Echipa din spatele acestui minunat magazin online nu este doar un grup
          de oameni. Sunt spirite ale generozității și iubirii pentru sărbători.
          Ei și-au dedicat timpul și pasiunea pentru a aduce bucurie în viețile
          altora în această perioadă specială a anului.
        </p>
        <p>
          Prin ușile virtuale ale magazinului „Craciun de Lux”, clienții sunt
          întâmpinați de o varietate largă de luminițe strălucitoare ce își
          dansează lumina caldă și magică. Este ca și cum stelele și-au coborât
          din ceruri pentru a lumina casele și sufletele celor ce simt farmecul
          sărbătorilor de iarnă.
        </p>
        <p>
          Puteți alege dintre braduții artificiali la ghiveci, fiecare un mic
          simbol al bucuriei și speranței. Fiecare ornament este ales cu grijă
          pentru a aduce zâmbete și emoții pline de căldură. Sunt atâtea
          opțiuni: globuri lucioase și sclipitoare, figurine adorabile,
          guirlande strălucitoare și multe altele, fiecare aducând un strop de
          magie în fiecare cămin.
        </p>
        <p>
          În această atmosferă plină de farmec, Crăciunul devine o poveste vie.
          Oamenii se adună în jurul bradului, împărtășind momente de bucurie și
          fericire. Fiecare achiziție din magazinul „Craciun de Lux” nu este
          doar o simplă cumpărătură, ci o promisiune că sărbătorile vor fi mai
          strălucitoare și mai memorabile.
        </p>
        <p>
          Iar astfel, lumina și magia Crăciunului continuă să răspândească
          fericire și căldură în inimile tuturor, datorită acestei mici lumi
          numite „Craciun de Lux”. Fiecare client devine parte din această
          poveste, contribuind la magia și bucuria Crăciunului.
        </p>
      </div>
    </div>
  );
};

export default DespreNoi;
