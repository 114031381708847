import React from "react";
import { Image, Input, Select, InputNumber } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./perdeaLumini.scss";

//fa
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons";
//helmet
import { Helmet } from "react-helmet";
const sizes = [
  {
    id: "3x1m",
    title: "3x1m",
    price: 99.99,
    oldPrice: 119.99,
  },
  {
    id: "3x2m",
    title: "3x2m",
    price: 109.99,
    oldPrice: 129.99,
  },
  {
    id: "3x3m",
    title: "3x3m",
    price: 119.99,
    oldPrice: 139.99,
  },
];

const colors = [
  {
    id: "alb-cald",
    title: "Lumină Alb Cald",
    // image: "/images/alb_cald.png",
    image: "/images/perdea4.jpg",
  },
  {
    id: "alb-rece",
    title: "Lumină Alb Rece",
    // image: "/images/alb_rece.png",
    image: "/images/perdea5.jpg",
  },
  {
    id: "multicolor",
    title: "Lumină Multicolor",
    // image: "/images/multicolor.png",
    image: "/images/perdea2.jpg",
  },
];
const PerdeaLumini = () => {
  const [size, setSize] = React.useState(sizes[0]);
  const [color, setColor] = React.useState(colors[0]);
  const [quantity, setQuantity] = React.useState(1);

  const handleAddToCart = () => {
    const itemid = 397 + sizes.indexOf(size) + colors.indexOf(color) * 3;
    if (localStorage.getItem("cart") === null) {
      localStorage.setItem(
        "cart",
        JSON.stringify([
          {
            id: parseInt(itemid),
            quantity: parseInt(quantity),
          },
        ])
      );
    } else {
      let cart = JSON.parse(localStorage.getItem("cart"));
      cart.push({
        id: parseInt(itemid),
        quantity: parseInt(quantity),
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    //redirect to /cart
    window.location.href = "/cart";
  };
  return (
    <div className="perdeaLumini">
      <Helmet
        title="Perdea Lumini - Crăciun de lux"
        description="Luminați-vă sărbătorile cu perdelele noastre de lumini de Crăciun!"
        keywords="Perdea Lumini - Crăciun de lux"
      />
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">Perdea Lumini</div>
          <div className="breadcrumbs">
            <a href="/">Home</a> / <a href="/magazin">Magazin</a> /{" "}
            <a href="/magazin/perdeaLumini">Perdea Lumini</a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="carousel-wrapper">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            width={"100%"}
          >
            <Image src="/images/perdea3.jpg" />
            <Image src="/images/perdea4.jpg" />
            <Image src="/images/perdea5.jpg" />
            <Image src="/images/perdea6.jpg" />
            <Image src="/images/perdea7.jpg" />
            <Image src="/images/perdea1.jpg" />
            <Image src="/images/perdea2.jpg" />
          </Carousel>
        </div>
        <div className="ribbon">
          <span className="ribbon-text">SUPER OFERTA 2 + 1 GRATIS</span>
          <img src="/images/ribbon.png" alt="ribbon" />
        </div>
        <div className="item-wrapper">
          {/* title, old price striked, new price next to it, button to order below, categories below */}
          <div className="item">
            <div className="item-image">
              <Image
                style={{
                  //round corners
                  borderRadius: 50,
                }}
                src={color.image}
              />
            </div>
            <div className="right">
              <div className="item-title">
                Perdea Lumini de Crăciun
                <br /> {color.title + " " + size.title}
              </div>
              <div className="item-price">
                <div className="old-price">{size.oldPrice} lei</div>
                <div className="new-price">{size.price} lei</div>
              </div>
              <div>
                <FontAwesomeIcon
                  style={{
                    color: "#d69b2d",
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  icon={faShippingFast}
                />
                Livrare 1-2 zile
              </div>
              <div className="colors">
                <div className="label">Culoare</div>
                <div className="colors-list">
                  {colors.map((c) => (
                    <div
                      className={`color ${color.id === c.id ? "selected" : ""}`}
                      onClick={() => setColor(c)}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 5,
                      }}
                    >
                      <img
                        style={{
                          margin: "auto",
                          aspectRatio: 1,
                          borderRadius: 10,
                        }}
                        src={c.image}
                      />
                      <div
                        style={{
                          fontSize: 12,
                          opacity: 0.8,
                          marginTop: 5,
                        }}
                        className="title"
                      >
                        {c.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form_item">
                <div className="label">Marime</div>
                <Select
                  placeholder="Alege marimea"
                  defaultValue={size.title}
                  onChange={(value) =>
                    setSize(sizes.find((s) => s.id === value))
                  }
                >
                  {sizes.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="form_item">
                <div className="label">Cantitate</div>
                <div className="item-button">
                  <InputNumber
                    size="large"
                    defaultValue={1}
                    min={1}
                    max={10}
                    style={{ width: 50 }}
                    onChange={(value) => setQuantity(value)}
                  />
                  <button onClick={handleAddToCart}>Adaugă în coș</button>
                </div>
              </div>
              <span>Categorie: Perdea Lumini de Crăciun</span>
              <br />
            </div>
          </div>
        </div>
        <p className="description-title">Descriere</p>
        <div className="description">
          <p>Intra în spiritul Crăciunului cu noi! 🎄</p>
          <p>
            Descoperă avantajele unei atmosfere festive perfecte, indiferent de
            dimensiunea locuinței tale. ✨
          </p>
          <p>
            De la 3x1m până la 3x3m, perdeaua noastră adaugă un farmec aparte
            oricărui colț al casei tale. 😀
          </p>
          <p>
            Alege din paleta de culori rafinate: alb cald pentru o eleganță
            subtilă, alb rece pentru un aer modern sau multicolor pentru o
            explozie de bucurie. 🎁
          </p>
          <p>
            Cu CrăciunDeLux, transformarea într-un peisaj de basm este la doar
            un clic distanță. 🎄
          </p>
          <p>
            Intră în spiritul sărbătorilor și bucură-te de fiecare moment
            într-un cadru desăvârșit. 🎀
          </p>
          <p>
            Comandă acum și oferă locuinței tale o doză generoasă de
            farmec festiv!💯
          </p>
        </div>
      </div>
    </div>
  );
};

export default PerdeaLumini;
