import React from "react";
import "./categoryId.scss";
import { useState } from "react";
import { useLocation } from "react-router-dom";
//imoprt /pubilc/data.json
import data from "../data.json";
//helmet
import { Helmet } from "react-helmet";

const categories = [
  "Perdea Lumini de Exterior/Interior",
  "Coronite de brad artificial",
  "Decorațiuni deosebite",
  "Ghirlanda din pin artificial",
  "Decoratiuni si globuri",
];

const CategoryId = () => {
  let location = useLocation();
  var id = location.pathname.split("/")[3].split("-")[0];
  var category = categories[id - 1];

  const [max, setMax] = useState(24);

  const products = data.filter((product) => product.category == parseFloat(id));
  return (
    <div className="categoryId">
      <Helmet
        title={`${category} - Crăciun de lux`}
        description={`Alege dintr-o gamă variată de produse. ${category} - Crăciun de lux.`}
        keywords="Categorii - Crăciun de lux"
      />
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">{category}</div>
          <div className="breadcrumbs">
            <a href="/">Home</a> / <a href="/magazin">Magazin</a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="details">
          Afisez 1 - {Math.min(products.length, 24)} din {products.length} de
          rezultate
        </div>
        <div className="categories-list">
          {products.map((category) => (
            <a href={"/magazin/item/" + category.id + "-" + category.name}>
              <div className="category-wrapper">
                <div className="category" key={category.id}>
                  <div className="category-image">
                    <img src={category.img_src} alt="category" />
                  </div>
                  <div className="category-title">{category.name}</div>
                  <div className="item-price">
                    <div className="old-price">{category.old_price} lei</div>
                    <div className="new-price">{category.new_price} lei</div>
                  </div>
                  <div className="item-button">
                    <button>Adaugă în coș</button>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryId;
