import React from "react";
import { Link } from "react-router-dom";
import "./cart.scss";
//helmet
import { Helmet } from "react-helmet";
import data from "../data.json";

//stripe
import { useStripe, useElements } from "@stripe/react-stripe-js";
//axios
import axios from "axios";

//fatrash
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

import { Form, Input, Select, notification, Checkbox } from "antd";

const judete = [
  { abr: "ab", nume: "Alba" },
  { abr: "ar", nume: "Arad" },
  { abr: "ag", nume: "Argeș" },
  { abr: "bc", nume: "Bacău" },
  { abr: "bh", nume: "Bihor" },
  { abr: "bn", nume: "Bistrița-Năsăud" },
  { abr: "bt", nume: "Botoșani" },
  { abr: "br", nume: "Brăila" },
  { abr: "bv", nume: "Brașov" },
  { abr: "b", nume: "București" },
  { abr: "bz", nume: "Buzău" },
  { abr: "cl", nume: "Călărași" },
  { abr: "cs", nume: "Caraș-Severin" },
  { abr: "cj", nume: "Cluj" },
  { abr: "ct", nume: "Constanța" },
  { abr: "cv", nume: "Covasna" },
  { abr: "db", nume: "Dâmbovița" },
  { abr: "dj", nume: "Dolj" },
  { abr: "gl", nume: "Galați" },
  { abr: "gr", nume: "Giurgiu" },
  { abr: "gj", nume: "Gorj" },
  { abr: "hg", nume: "Harghita" },
  { abr: "hr", nume: "Harghita" },
  { abr: "hd", nume: "Hunedoara" },
  { abr: "il", nume: "Ialomița" },
  { abr: "is", nume: "Iași" },
  { abr: "if", nume: "Ilfov" },
  { abr: "mm", nume: "Maramureș" },
  { abr: "mh", nume: "Mehedinți" },
  { abr: "ms", nume: "Mureș" },
  { abr: "nt", nume: "Neamț" },
  { abr: "ot", nume: "Olt" },
  { abr: "ph", nume: "Prahova" },
  { abr: "sj", nume: "Sălaj" },
  { abr: "sm", nume: "Satu Mare" },
  { abr: "sb", nume: "Sibiu" },
  { abr: "sv", nume: "Suceava" },
  { abr: "tr", nume: "Teleorman" },
  { abr: "tm", nume: "Timiș" },
  { abr: "tl", nume: "Tulcea" },
  { abr: "vl", nume: "Vâlcea" },
  { abr: "vs", nume: "Vaslui" },
  { abr: "vn", nume: "Vrancea" },
];

const Cart = () => {
  const [loading, setLoading] = React.useState(false);
  const [stripeLink, setStripeLink] = React.useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [cart, setCart] = React.useState(
    JSON.parse(localStorage.getItem("cart")) == null
      ? []
      : JSON.parse(localStorage.getItem("cart"))
  );
  const [cash, setCash] = React.useState(false);
  const [packaged, setPackaged] = React.useState(false);

  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const handleOpenStripeLink = async () => {
    localStorage.removeItem("cart");
    setLoading(true);
    setStripeLink(null);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    if (cash) {
      axios
        .post(process.env.REACT_APP_API_URL + "/orders/addCashOrder", {
          paymentIntentId: "",
          name: values.name,
          surname: values.surname,
          phone: values.phone,
          email: values.email,
          county: values.judet,
          city: values.oras,
          address: values.adresa,
          postalCode: values.codPostal,
          cash: cash,
          packaged: packaged,
          itemsOrdered: cart,
          voucher: voucher,
        })
        .then((res) => {
          setLoading(false);
          localStorage.removeItem("cart");
          window.location.href = "/multumim";
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          openNotification(
            "error",
            "Eroare la finalizarea comenzii!",
            "Te rugam sa incerci din nou."
          );
        });
    } else {
      var paymentLink = "";
      axios
        .post(process.env.REACT_APP_API_URL + "/orders/getPaymentLink", {
          name: values.name,
          surname: values.surname,
          phone: values.phone,
          email: values.email,
          county: values.judet,
          city: values.oras,
          address: values.adresa,
          postalCode: values.codPostal,
          cash: cash,
          packaged: packaged,
          itemsOrdered: cart,
          voucher: voucher,
        })
        .then((res) => {
          paymentLink = res.data.url;
          setStripeLink(paymentLink);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          openNotification(
            "error",
            "Eroare la finalizarea comenzii!",
            "Te rugam sa incerci din nou."
          );
        });
    }
  };

  const [voucherNeaplicat, setVoucherNeaplicat] = React.useState("");
  const [voucher, setVoucher] = React.useState("");
  //   const [name, setName] = React.useState("");
  //  const [surname, setSurname] = React.useState("");
  //   const [phone, setPhone] = React.useState("");
  //   const [email, setEmail] = React.useState("");
  //   const [judet, setJudet] = React.useState("");
  //   const [oras, setOras] = React.useState("");
  //   const [adresa, setAdresa] = React.useState("");
  // const [codPostal, setCodPostal] = React.useState("");

  return (
    <div className="cart">
      <Helmet
        title="Coșul meu - Crăciun de lux"
        description="Cosul meu de cumparaturi - Crăciun de lux."
        keywords="Categorii - Crăciun de lux"
      />
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">Coș</div>
          <div className="breadcrumbs">
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cart-items">
          {cart && cart.length > 0 ? (
            <>
              {cart.map((cartItem) => {
                const item = data.filter((item) => item.id == cartItem.id)[0];
                return (
                  <div className="cart-item" key={item.id}>
                    <div className="cart-item-image">
                      <img src={item.img_src} alt="item" />
                    </div>
                    <div className="cart-item-details">
                      <div className="cart-item-title">{item.name}</div>
                      <div className="cart-item-price">
                        {item.new_price * cartItem.quantity} lei
                      </div>
                      <div className="cart-item-quantity">
                        Numar bucati: {cartItem.quantity}
                      </div>
                      <div className="cart-item-remove">
                        <button
                          onClick={() => {
                            let cart = JSON.parse(localStorage.getItem("cart"));
                            cart = cart.filter(
                              (cartItem) => cartItem.id != item.id
                            );
                            localStorage.setItem("cart", JSON.stringify(cart));
                            window.location.reload();
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                <Input
                  size="large"
                  style={{
                    width: 300,
                  }}
                  placeholder="Voucher"
                  onChange={(e) => setVoucherNeaplicat(e.target.value)}
                />
                <button
                  className="voucherButton"
                  onClick={() => {
                    if (voucherNeaplicat.toUpperCase() === "PRODAN15") {
                      setVoucher("PRODAN15");
                      openNotification(
                        "success",
                        "Voucher aplicat cu succes!",
                        "Discount de 15% a fost aplicat."
                      );
                    } else {
                      openNotification(
                        "error",
                        "Voucher incorect!",
                        "Voucher-ul nu este valid."
                      );
                    }
                  }}
                >
                  Aplica voucher
                </button>
              </div>
            </>
          ) : (
            <div className="empty-cart">
              <div className="empty-cart-title">Coșul tău este gol.</div>
              <div className="empty-cart-text">
                Ai putea să adaugi câteva produse în coș sau să te întorci la
                magazin.
              </div>
              <a href="/magazin/perdeaLumini">
                <button className="empty-cart-button">Înapoi la magazin</button>
              </a>
            </div>
          )}
        </div>
        {cart && cart.length > 0 && (
          <div className="checkout-info">
            <div
              className="row"
              style={{
                marginLeft: "40%",
              }}
            >
              Total Coș
            </div>
            {cart && cart.length > 0 ? (
              <div className="row">
                <div className="left">Sub-total </div>
                <div className="right">
                  {cart
                    .reduce(
                      (acc, cartItem) =>
                        acc +
                        data.filter((item) => item.id == cartItem.id)[0]
                          .new_price *
                          cartItem.quantity,
                      0
                    )
                    .toFixed(2)}{" "}
                  lei
                </div>
              </div>
            ) : null}
            {packaged ? (
              <div className="row">
                <div className="left">Transport + Împachetare cadou</div>
                <div className="right">29.99 lei</div>
              </div>
            ) : (
              <div className="row">
                <div className="left">Transport</div>
                <div className="right">14.99 lei</div>
              </div>
            )}
            {voucher == "PRODAN15" && (
              <div className="row">
                <div className="left">Discount</div>
                <div className="right">15%</div>
              </div>
            )}
            <div
              className="line"
              style={{
                width: "100%",
                marginBottom: "10px",
                border: "1px solid #e0e0e0",
              }}
            ></div>
            <div className="row">
              <div className="left">Total</div>
              <div className="right">
                {(
                  cart.reduce(
                    (acc, cartItem) =>
                      acc +
                      data.filter((item) => item.id == cartItem.id)[0]
                        .new_price *
                        cartItem.quantity,
                    0
                  ) *
                    (voucher == "PRODAN15" ? 0.85 : 1) +
                  (packaged ? 29.99 : 14.99)
                ).toFixed(2)}
                {" lei"}
              </div>
            </div>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <Checkbox onChange={(e) => setPackaged(e.target.checked)}>
                Împachetare cadou
              </Checkbox>
            </div>
            <br />
            <h2>Date de livrare</h2>
            <Form
              onFinish={handleFormSubmit}
              size="large"
              name="buyer-info"
              className="buyer-info"
            >
              <Form.Item
                style={{
                  width: "45%",
                }}
                name="name"
                label="Nume"
                rules={[
                  {
                    required: true,
                    message: "Introduceti numele!",
                  },
                ]}
              >
                <Input placeholder="Nume" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                  marginLeft: "10%",
                }}
                label="Prenume"
                name="surname"
                rules={[
                  {
                    required: true,
                    message: "Introduceti prenumele!",
                  },
                ]}
              >
                <Input placeholder="Prenume" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                }}
                name="phone"
                label="Telefon"
                rules={[
                  {
                    required: true,
                    message: "Introduceti numarul de telefon!",
                  },
                ]}
              >
                <Input placeholder="Telefon" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                  marginLeft: "10%",
                }}
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Introduceti adresa de email!",
                  },
                  {
                    type: "email",
                    message: "Introduceti o adresa de email valida!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                }}
                name="judet"
                label="Judet"
                rules={[
                  {
                    required: true,
                    message: "Introduceti judetul!",
                  },
                ]}
              >
                <Select placeholder="Judet">
                  {judete.map((judet) => (
                    <Select.Option value={judet.abr}>
                      {judet.nume}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                  marginLeft: "10%",
                }}
                label="Oras"
                name="oras"
                rules={[
                  {
                    required: true,
                    message: "Introduceti orasul!",
                  },
                ]}
              >
                <Input placeholder="Oras" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                }}
                name="adresa"
                label="Adresa"
                rules={[
                  {
                    required: true,
                    message: "Introduceti adresa!",
                  },
                ]}
              >
                <Input placeholder="Adresa" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                }}
                name="codPostal"
                label="Cod Postal"
                rules={[
                  {
                    required: true,
                    message: "Introduceti codul postal!",
                  },
                ]}
              >
                <Input placeholder="Cod Postal" />
              </Form.Item>
              <Form.Item
                style={{
                  width: "45%",
                  marginLeft: "10%",
                }}
                label="Metoda de plata"
              >
                <Select placeholder="Plata" onChange={(e) => setCash(e)}>
                  <Select.Option value={true}>Cash, la livrare</Select.Option>
                  <Select.Option value={false}>Card</Select.Option>
                </Select>
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 40,
                }}
              >
                {cash && !loading ? (
                  <button disabled={loading}>Finalizează comanda</button>
                ) : (
                  <>
                    {stripeLink == null && !loading && (
                      <button disabled={loading}>Confirmă datele</button>
                    )}
                  </>
                )}
              </div>
            </Form>
            {stripeLink && !loading && !cash && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <a href={stripeLink} target="_blank">
                  <button onClick={handleOpenStripeLink} disabled={loading}>
                    Continuă la plata
                  </button>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
