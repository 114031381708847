import React from "react";
import { Link } from "react-router-dom";
import "./categories.scss";
//helmet
import { Helmet } from "react-helmet";

const categories = [
  {
    id: 1,
    link: "/magazin/perdeaLumini",
    image: "/images/category1_2.jpg",
    title: "Perdea Lumini de Exterior/Interior",
    nrItems: 3,
  },
  {
    id: 2,
    link: "/magazin/category/2-coronite-brad-artificial",
    image: "/images/category2_2.jpg",
    title: "Coronite de brad artificial",
    nrItems: 35,
  },
  {
    id: 3,
    link: "/magazin/category/3-decoratiuni-deosebite",
    image: "/images/category3_2.jpg",
    title: "Decorațiuni deosebite",
    nrItems: 201,
  },
  {
    id: 4,
    link: "/magazin/category/4-ghirlanda-pin-artificial",
    image: "/images/category4_2.jpg",
    title: "Ghirlanda din pin artificial",
    nrItems: 14,
  },
  {
    id: 5,
    link: "/magazin/category/5-decoratiuni-globuri",
    image: "/images/category5_2.jpg",
    title: "Decoratiuni si globuri",
    nrItems: 146,
  },
];

const Categories = () => {
  return (
    <div className="categoriesLayout">
      <Helmet
        title="Categorii - Crăciun de lux"
        description="Iată cele mai frumoase decorațiuni de Crăciun pentru casă și grădină. Alege dintr-o gamă variată de produse de calitate superioară."
        keywords="Categorii - Crăciun de lux"
      />
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">Magazin</div>
          <div className="breadcrumbs">
            <Link to="/">Home</Link> / <Link to="/magazin">Magazin</Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="categories-list">
          {categories.map((category) => (
            <div className="category-wrapper">
              <div className="category" key={category.id}>
                <Link to={`${category.link}`}>
                  <div className="category-image">
                    <img src={category.image} alt="category" />
                  </div>
                  <div className="category-title">
                    {category.title}
                    <br />
                    {" (" + category.nrItems + ")"}
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
