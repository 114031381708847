import React from "react";
import "./contact.scss";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className="contact">
      <Helmet
        title="Contact Us - Crăciun de lux"
        description="Ia legătura cu noi pentru a afla mai multe despre produsele noastre."
        keywords="Contact Us - Crăciun de lux"
      />
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">Contact Us</div>
          <div className="breadcrumbs">
            <a href="/">Home</a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-1">
          <div className="title">Contactati-ne</div>
          <br />
          <div className="text">
            <FontAwesomeIcon icon={faEnvelope} />
            {" Email:"}
            <a href="mailto:craciundelux1@gmail.com">
              {" "}
              craciundelux1@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
