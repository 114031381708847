import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer";
import HomePage from "./components/homePage";
import MainHeader from "./components/mainHeader";
import Categories from "./components/categories";
import Contact from "./components/contact";
import DespreNoi from "./components/despreNoi";
import PerdeaLumini from "./components/perdeaLumini";
import CategoryId from "./components/categoryId";
import Item from "./components/item";
import Cart from "./components/cart";
import Multumim from "./components/multumim";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <Router>
      <div className="App">
        <MainHeader />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/magazin" element={<Categories />} />
          <Route path="/magazin/perdeaLumini" element={<PerdeaLumini />} />
          <Route path="/magazin/category/:id" element={<CategoryId />} />
          <Route path="/magazin/item/:id" element={<Item />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/despre-noi" element={<DespreNoi />} />
          <Route
            path="/cart"
            element={
              <Elements stripe={stripePromise}>
                <Cart />
              </Elements>
            }
          />
          <Route path="/multumim" element={<Multumim />} />

          <Route path="*" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
