import React from "react";
import { Helmet } from "react-helmet";
import "./multumim.scss";

const Multumim = () => {
  return (
    <div className="multumim">
      <Helmet
        title={`Confirmare comanda - Crăciun de lux`}
        description={`Confirmare comanda - Crăciun de lux.`}
        keywords="Confirmare comanda - Crăciun de lux"
      />
      <div className="page-header-wrapper">
        <div className="page-header">
          <div className="title">Confirmare comanda</div>
          <div className="breadcrumbs">
            <a href="/">Home</a>
          </div>
        </div>
      </div>
      <div className="container">
        <h2> Comanda dvs. a fost plasată cu succes! </h2>
        <p> Veți primi un email de confirmare în scurt timp. </p>
        <p>
          Pentru nelămuriri sau întrebări, vă rugăm să ne contactați la adresa
          de email{" "}
          <a href="mailto:craciundelux1@gmail.com">craciundelux1@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

export default Multumim;
