import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="col-1">
          <div className="logo">
            <img src="/images/logo.png" alt="logo" />
          </div>
          <br />
          <br />
          <div className="logo-text">Urmareste-ne</div>
        </div>
        <div className="col-2">
          <li>Informatii</li>
          <br />
          <li>
            <a href="/despre-noi">Despre noi</a>
          </li>
          <li>
            <a href="/magazin">Categorii</a>
          </li>
          <li>
            <a href="/contact-us">Contact</a>
          </li>
          <br />
          <li>Servicii clienti</li>
          <br />
          <li>
            <a href="https://anpc.ro/">ANPC</a>
          </li>
          <li>Termeni si conditii</li>
          <li>Politica de confidentialitate</li>
          <li>Livrare si retur</li>
          <li>Formular retur</li>
        </div>
        <div className="col-3">
          <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener">
            <img
              decoding="async"
              className="aligncenter"
              src="https://craciundelux.ro/wp-content/uploads/2023/10/sal.png"
            />
          </a>
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener"
          >
            <img
              decoding="async"
              className="aligncenter"
              src="https://craciundelux.ro/wp-content/uploads/2023/10/sol.png"
            />
          </a>
        </div>
      </div>
      <div className="copyright">
        Craciun de lux © 2023. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
