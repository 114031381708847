import React from "react";
import "./homePage.scss";

const categories = [
  {
    id: 1,

    link: "/magazin/perdeaLumini",
    image: "/images/category1.jpg",
    title: "Perdea Lumini de Exterior/Interior",
  },
  {
    id: 2,
    link: "/magazin/category/2-coronite-brad-artificial",

    image: "/images/category2.jpg",
    title: "Coronite de brad artificial",
  },
  {
    id: 3,
    link: "/magazin/category/3-decoratiuni-deosebite",

    image: "/images/category3.jpg",
    title: "Decorațiuni deosebite",
  },
  {
    id: 4,
    link: "/magazin/category/4-ghirlanda-pin-artificial",

    image: "/images/category4.jpg",
    title: "Ghirlanda din pin artificial",
  },
  {
    id: 5,
    link: "/magazin/category/5-decoratiuni-globuri",

    image: "/images/category5.jpg",
    title: "Decoratiuni si globuri",
  },
];

const HomePage = () => {
  return (
    <div className="homePage">
      <div className="parallax" />
      <div className="spacer" style={{ marginTop: "10px" }} />
      <div className="align-center">
        <div className="shop_button ">Vezi categoriile</div>
      </div>
      <div className="spacer" style={{ marginTop: "30px" }} />
      <div className="badges">
        <div className="row one">
          <div className="badge">
            <div className="badge-image">
              <img src="/images/badge1.svg" alt="badge" />
            </div>
            <div className="content">
              <div className="badge-title">Livrare rapida</div>
              <div className="badge-description">1-2 zile </div>
            </div>
          </div>
          <div className="badge">
            <div className="badge-image">
              <img src="/images/badge2.svg" alt="badge" />
            </div>
            <div className="content">
              <div className="badge-title">Garanție</div>
              <div className="badge-description">14 zile drept la retur</div>
            </div>
          </div>
        </div>
        <div className="row two">
          <div className="badge">
            <div className="badge-image">
              <img src="/images/badge3.svg" alt="badge" />
            </div>
            <div className="content">
              <div className="badge-title">Livrare</div>
              <div className="badge-description">In toata tara</div>
            </div>
          </div>
          <div className="badge">
            <div className="badge-image">
              <img src="/images/badge4.svg" alt="badge" />
            </div>
            <div className="content">
              <div className="badge-title">Produse premium</div>
              <div className="badge-description">
                Beneficiezi de cele mai premium produse
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer" style={{ marginTop: "30px" }} />
      <div className="container">
        <div className="categories">
          {categories.map((category) => (
            <div className="category" key={category.id}>
              <div className="category-image">
                <a href={category.link}>
                  <img src={category.image} />
                </a>
              </div>
              <div className="category-title">{category.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="spacer" style={{ marginTop: "15px" }} />
      <div className="align-center">
        <div className="shop_button ">Vezi toate categoriile</div>
      </div>
      <div className="spacer" style={{ marginTop: "100px" }} />
    </div>
  );
};

export default HomePage;
